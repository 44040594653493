import React, { useEffect, useState } from 'react';
import seek_logo_url, {
	ReactComponent as SeekLogo,
} from '../../assets/seek-logo.svg';
import './Leaderboard.css';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import Header from '../../components-v2/header/Header';
import { Mixpanel } from '../../analytics/Mixpanel';
import BackButton from '../../components-v2/buttons/BackButton';
import LeaderNudge from './components/LeaderNudge';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderBoardUsers } from '../../services/Firebase';
import { getUserListWithSpecialUser } from '../../utils/utils';
import {
	setLeaderboardUsers,
	setTopFourLeaderboardUsers,
} from '../../store/features/LeaderboardSlice';

const Leaderboard = () => {
	const userData = useAuthenticatedUsers();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location;
	// const [loader,setLoader] = useState<boolean>(false);
	const leaderboardUsers = useSelector(
		(state: any) => state?.leaderboard?.data?.leaderboardUsers
	);
	const user = state?.user;

	const currentUserLeaderboardStats = leaderboardUsers?.find(
		(leaderboardUser: any) => leaderboardUser?.phone === user?.phone
	);
	const [currentPage, setCurrentPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

	useEffect(() => {
		const fetchLeaderboardUsers = async () => {
			try {
				const result = await getLeaderBoardUsers(
					currentPage,
					user?.uid || userData?.uid
				);
				const newUsers = result?.users || [];

				if (newUsers.length > 0) {
					const updatedLeaderboardUsers = [...leaderboardUsers, ...newUsers];
					const topFourLeaderboardUsers = getUserListWithSpecialUser(
						updatedLeaderboardUsers,
						result?.currentUser
					);

					dispatch(setLeaderboardUsers(updatedLeaderboardUsers));
					dispatch(setTopFourLeaderboardUsers(topFourLeaderboardUsers));
				}

				if (newUsers.length < 20) {
					// Assuming page size is 20
					setHasMore(false); // No more data to load
				}
			} catch (error) {
				console.error('Error fetching leaderboard users:', error);
			}
		};

		fetchLeaderboardUsers();
	}, [currentPage, userData]);

	useEffect(() => {
		const handleScroll = () => {
			if (
				window.innerHeight + document.documentElement.scrollTop >=
					document.documentElement.offsetHeight &&
				hasMore
			) {
				if (!hasScrolledToEnd) {
					console.log('Add rows');
					setHasScrolledToEnd(true);
					setCurrentPage((prevPage) => prevPage + 1);
				}
			} else {
				setHasScrolledToEnd(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, [hasMore]);

	const onKarmaPointsClickHandler = () => {
		Mixpanel.track('click_karma_points_badge', {
			event_action: 'clicked',
			context_user_id: user?.uid,
			total_karma_points: user?.total_karma || 0,
			event_page: 'home_screen',
		});
		navigate('/karma', {
			state: { email: user?.email, total_karma: user?.total_karma },
			replace: false,
		});
	};
	return (
		<div className={'leaderboard-container'}>
			<Header
				onClick={onKarmaPointsClickHandler}
				logoUrl={seek_logo_url}
				karma_points={user?.total_karma}
			/>
			<div style={{ width: '90%', maxWidth: '400px', marginTop: '24px' }}>
				<BackButton onClick={navigate.bind(null, -1)} />
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					marginTop: '24px',
					marginBottom: '16px',
					maxWidth: '375px',
					width: '90%',
				}}>
				<span className={'leaderboard-screen-title'}>
					{'Weekly Leaderboard'}
				</span>
				<span className={'leaderboard-screen-subtitle'}>
					{'Climb the charts and find your inner peace:'}
				</span>
				<span className={'leaderboard-screen-subtitle'}>
					{"This week's top meditators!"}
				</span>
			</div>
			<LeaderNudge
				currentUserLeaderboardStats={currentUserLeaderboardStats}
				onClick={() => {}}
				phone={user?.phone || userData?.phone}
				leaderboardUsers={leaderboardUsers}
			/>
		</div>
	);
};

export default Leaderboard;

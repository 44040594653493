import React, { useEffect, useRef } from 'react';
import { Mixpanel } from '../../analytics/Mixpanel';
import { ReactComponent as Watch } from '../../assets/watch_blue.svg';
import { ReactComponent as Target } from '../../assets/target.svg';

interface IconWithTitleProps {
	title: string;
}

export const IconWithTitle: React.FC<IconWithTitleProps> = ({ title }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'row',
				justifyContent: 'start',
				marginTop: '8px',
				paddingRight: '12px',
			}}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='16'
				viewBox='0 0 16 16'
				fill='none'>
				<rect width='16' height='16' rx='8' fill='#8B2EFF' />
				<path
					d='M12.1967 6.19678L12.1968 6.19667C12.3373 6.05605 12.4162 5.86542 12.4162 5.66667C12.4162 5.46792 12.3373 5.2773 12.1968 5.13667L12.1966 5.13645C12.056 4.996 11.8654 4.91711 11.6666 4.91711C11.4679 4.91711 11.2772 4.996 11.1366 5.13645L11.1365 5.13656L6.99994 9.27312L4.86619 7.13937C4.79818 7.06733 4.71657 7.00946 4.62605 6.96913C4.53405 6.92814 4.43473 6.9061 4.33403 6.90432C4.23333 6.90254 4.1333 6.92107 4.03991 6.95879L4.13354 7.19059L4.03991 6.95879C3.94652 6.99651 3.86169 7.05265 3.79047 7.12387C3.71925 7.19509 3.66311 7.27992 3.62539 7.37331C3.58767 7.4667 3.56914 7.56673 3.57092 7.66743C3.57269 7.76813 3.59474 7.86745 3.63573 7.95945C3.67606 8.04997 3.73393 8.13158 3.80597 8.19959L6.46983 10.8635L6.46994 10.8636C6.61057 11.004 6.80119 11.0829 6.99994 11.0829C7.19869 11.0829 7.38931 11.004 7.52994 10.8636L7.53005 10.8635L12.1967 6.19678Z'
					fill='white'
					stroke='white'
					stroke-width='0.5'
				/>
			</svg>
			<span className={'highlights-point'}>{title}</span>
		</div>
	);
};

interface CustomeIconWithTitleProps {
	title: string;
	Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const CustomeIconWithTitle: React.FC<CustomeIconWithTitleProps> = ({
	title,
	Icon,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'row',
				justifyContent: 'start',
				marginTop: '8px',
				paddingRight: '12px',
				fontFamily: 'Gordita-Medium',
				color: '#1B1625',
				fontWeight: '500',
			}}>
			<Icon width='24' height='24' />
			<span
				className={'highlights-point'}
				style={{
					fontFamily: 'Gordita-Medium',
					color: '#1B1625',
					fontWeight: '500',
					fontSize: '14px',
				}}>
				{title}
			</span>
		</div>
	);
};

interface ModuleCardCollapsedProps {
	moduleTitle: string;
	moduleName: number;
	index: number;
	selectedIndex: number | null;
	setSelectedIndex: any;
	description: string;
	duration: string;
	exercise: string;
	highlights: any;
}

export const CourseModuleCardCollapsed: React.FC<ModuleCardCollapsedProps> = ({
	setSelectedIndex,
	moduleTitle,
	moduleName,
	selectedIndex,
	index,
	description,
	duration,
	exercise,
	highlights,
}) => {
	const isSelected = selectedIndex === index;

	const onClickModule = () => {
		Mixpanel.track('click_course_landing_page_modules', {
			event_action: 'clicked',
			moduleTitle: moduleTitle,
			moduleName: moduleName,
			event_page: 'course_landing_page',
		});
		if (selectedIndex == index) {
			setSelectedIndex(null);
		} else {
			setSelectedIndex(index);
		}
	};

	return (
		<div
			onClick={onClickModule}
			className={`${
				isSelected ? 'module-card-expanded' : 'module-card-collapsed'
			}`}>
			{isSelected ? (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span
						style={{
							color: '#8B2EFF',
							fontFamily: 'Poppins-SemiBold',
							fontSize: '10px',
							fontStyle: 'normal',
							fontWeight: 600,
							lineHeight: '16px', // or '160%'
						}}>
						{moduleTitle}
					</span>
					<div
						style={{
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'space-between',
							width: '320px',
						}}>
						<span
							style={{
								color: '#2A2A2A',
								fontFamily: 'Lora-Regular',
								fontSize: '16px',
								fontStyle: 'normal',
								fontWeight: 400,
								marginBottom: '10px',
								lineHeight: '24px', // or '150%'
							}}>
							{moduleName}
						</span>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 20 20'
							fill='none'>
							<path
								d='M10 8.99991L13.42 12.1409C13.5501 12.2698 13.7259 12.342 13.909 12.342C14.0921 12.342 14.2679 12.2698 14.398 12.1409C14.4622 12.0776 14.5133 12.0022 14.5481 11.919C14.5829 11.8359 14.6009 11.7466 14.6009 11.6564C14.6009 11.5662 14.5829 11.477 14.5481 11.3938C14.5133 11.3106 14.4622 11.2352 14.398 11.1719L10.49 7.34191C10.3596 7.21323 10.1837 7.14109 10.0005 7.14109C9.81728 7.14109 9.64143 7.21323 9.511 7.34191L5.603 11.1719C5.53875 11.2352 5.48773 11.3106 5.4529 11.3938C5.41807 11.477 5.40014 11.5662 5.40014 11.6564C5.40014 11.7466 5.41807 11.8359 5.4529 11.919C5.48773 12.0022 5.53875 12.0776 5.603 12.1409C5.73313 12.2698 5.90886 12.342 6.092 12.342C6.27513 12.342 6.45086 12.2698 6.581 12.1409L10 8.99991Z'
								fill='#8B2EFF'
							/>
						</svg>
					</div>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='321'
						height='2'
						viewBox='0 0 321 2'
						fill='none'>
						<path
							d='M1 1H320'
							stroke='#D6D6D6'
							stroke-width='0.64'
							stroke-linecap='round'
							stroke-dasharray='4 4'
						/>
					</svg>
					<span
						style={{
							color: '#2A2A2A',
							width: '324px',
							textAlign: 'start',
							fontFamily: 'Poppins-Regular',
							fontSize: '14px',
							marginTop: '10px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: '24px', // or '150%'
						}}>
						{description}
					</span>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'start',
							marginTop: '10px',
						}}>
						<Watch width={24} height={24} />
						<span className={'upcoming-course-description'}>{duration}</span>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'start',
							marginTop: '10px',
							marginBottom: '10px',
						}}>
						<Target style={{ marginLeft: '4px' }} width={16} height={16} />
						<span
							style={{ marginLeft: '4px' }}
							className={'upcoming-course-description'}>
							{exercise}
						</span>
					</div>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='321'
						height='2'
						viewBox='0 0 321 2'
						fill='none'>
						<path
							d='M1 1H320'
							stroke='#D6D6D6'
							stroke-width='0.64'
							stroke-linecap='round'
							stroke-dasharray='4 4'
						/>
					</svg>

					<span className={'upcoming-course-highlights'}>{'Highlights'}</span>
					{highlights?.map((item: any, index: number) => (
						<IconWithTitle title={item} />
					))}
				</div>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span
						style={{
							color: '#8B2EFF',
							fontFamily: 'Poppins-SemiBold',
							fontSize: '10px',
							fontStyle: 'normal',
							fontWeight: 600,
							lineHeight: '16px', // or '160%'
						}}>
						{moduleTitle}
					</span>
					<span
						style={{
							color: '#2A2A2A',
							fontFamily: 'Lora-Regular',
							fontSize: '16px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: '24px', // or '150%'
						}}>
						{moduleName}
					</span>
				</div>
			)}
			<svg
				style={{ alignSelf: 'center' }}
				xmlns='http://www.w3.org/2000/svg'
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'>
				<path
					d='M10.0002 11L13.4202 7.85897C13.5503 7.73012 13.7261 7.65784 13.9092 7.65784C14.0923 7.65784 14.2681 7.73012 14.3982 7.85897C14.4625 7.92224 14.5135 7.99766 14.5483 8.08084C14.5831 8.16402 14.6011 8.2533 14.6011 8.34347C14.6011 8.43365 14.5831 8.52292 14.5483 8.6061C14.5135 8.68928 14.4625 8.7647 14.3982 8.82797L10.4902 12.658C10.3598 12.7866 10.1839 12.8588 10.0007 12.8588C9.81749 12.8588 9.64164 12.7866 9.51121 12.658L5.60321 8.82797C5.53896 8.7647 5.48794 8.68928 5.45311 8.6061C5.41828 8.52292 5.40035 8.43365 5.40035 8.34347C5.40035 8.2533 5.41828 8.16402 5.45311 8.08084C5.48794 7.99766 5.53896 7.92224 5.60321 7.85897C5.73335 7.73012 5.90908 7.65784 6.09221 7.65784C6.27534 7.65784 6.45108 7.73012 6.58121 7.85897L10.0002 11Z'
					fill='#8B2EFF'
				/>
			</svg>
		</div>
	);
};

interface ModuleCardCollapsedPropsNew {
	moduleTitle: string;
	weekDays: any;
	index: number;
	selectedIndex: number | null;
	setSelectedIndex: any;
}

export const CourseModuleCardCollapsedNew: React.FC<
	ModuleCardCollapsedPropsNew
> = ({ setSelectedIndex, moduleTitle, selectedIndex, index, weekDays }) => {
	const isSelected = selectedIndex === index;
	// console.log("CourseModuleCardCollapsedNew", moduleTitle);

	const cardRef = useRef<any>(null);

	// useEffect(() => {
	// 	if (isSelected) {
	// 		cardRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	// 	}
	// }, [isSelected]);

	const onClickModule = () => {
		Mixpanel.track('click_course_landing_page_week_schedule', {
			event_action: 'clicked',
			week_number: moduleTitle,
			event_page: 'course_landing_page',
		});
		if (selectedIndex === index) {
			setSelectedIndex(null);
		} else {
			setSelectedIndex(index);
			setTimeout(() => {
				cardRef?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}, 100);
		}
	};

	return (
		<div
			ref={cardRef}
			onClick={onClickModule}
			className={`${
				isSelected
					? 'module-course-card-expanded'
					: 'module-course-card-collapsed'
			}`}>
			{isSelected ? (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span
						style={{
							color: '#4A4A4A',
							fontFamily: 'Gordita-Medium',
							fontSize: '20px',
							fontStyle: 'normal',
							fontWeight: 500,
							position: 'relative',
							lineHeight: '28px', // or '160%'
						}}>
						{moduleTitle}
						<svg
							style={{ position: 'absolute', right: '0' }}
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 20 20'
							fill='none'>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M4.43741 13.0592C4.1572 12.7759 4.00003 12.3935 4.00003 11.995C4.00003 11.5964 4.1572 11.214 4.43741 10.9307L8.84605 6.48303C9.17306 6.16052 9.59607 6.00002 10.0146 6.00002C10.4331 6.00002 10.8486 6.16052 11.1681 6.48303L15.5633 10.9157C15.8431 11.1992 16 11.5816 16 11.9799C16 12.3783 15.8431 12.7607 15.5633 13.0442C15.4255 13.184 15.2613 13.295 15.0803 13.3708C14.8993 13.4465 14.705 13.4856 14.5087 13.4856C14.3125 13.4856 14.1182 13.4465 13.9371 13.3708C13.7561 13.295 13.5919 13.184 13.4542 13.0442L10.0071 9.56863L6.54648 13.0592C6.40842 13.1985 6.24415 13.309 6.06316 13.3844C5.88216 13.4598 5.68803 13.4987 5.49195 13.4987C5.29587 13.4987 5.10173 13.4598 4.92073 13.3844C4.73974 13.309 4.57547 13.1985 4.43741 13.0592Z'
								fill='url(#paint0_linear_138_1559)'
							/>
							<defs>
								<linearGradient
									id='paint0_linear_138_1559'
									x1='9.04548'
									y1='13.4987'
									x2='18.2932'
									y2='9.23894'
									gradientUnits='userSpaceOnUse'>
									<stop stop-color='#4462EF' />
									<stop offset='1' stop-color='#1B3DD8' />
								</linearGradient>
							</defs>
						</svg>
					</span>
					{weekDays.map((item: any, index: number) => (
						<div key={index} style={{ marginTop: index == 0 ? '12px' : '0px' }}>
							<div
								style={{
									color: '#1B1625',
									fontFamily: 'Gordita-Medium',
									fontSize: '16px',
									fontStyle: 'normal',
									fontWeight: 500,
									lineHeight: '24px', // or '160%'
								}}>{`Day ${item.day}`}</div>
							<div
								style={{
									color: '#4A4A4A',
									fontFamily: 'Gordita-Regular',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 400,
									lineHeight: '20px', // or '160%'
								}}>
								{item.task_description}
							</div>
							{index !== weekDays.length - 1 && (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='321'
									height='2'
									viewBox='0 0 321 2'
									fill='none'>
									<path
										d='M1 1H320'
										stroke='#FFD8AC'
										stroke-linecap='round'
										stroke-dasharray='4 4'
									/>
								</svg>
							)}
						</div>
					))}
				</div>
			) : (
				<span
					style={{
						color: '#4A4A4A',
						fontFamily: 'Gordita-Regular',
						fontSize: '20px',
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: '28px', // or '160%'
					}}>
					{moduleTitle}
				</span>
			)}

			{!isSelected && (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill='none'>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M4.43741 6.94077C4.1572 7.22411 4.00003 7.60654 4.00003 8.00505C4.00003 8.40356 4.1572 8.78599 4.43741 9.06933L8.84605 13.517C9.17306 13.8395 9.59607 14 10.0146 14C10.4331 14 10.8486 13.8395 11.1681 13.517L15.5633 9.08433C15.8431 8.8008 16 8.41844 16 8.02005C16 7.62166 15.8431 7.23931 15.5633 6.95577C15.4255 6.81599 15.2613 6.70499 15.0803 6.62922C14.8993 6.55346 14.705 6.51444 14.5087 6.51444C14.3125 6.51444 14.1182 6.55346 13.9371 6.62922C13.7561 6.70499 13.5919 6.81599 13.4542 6.95577L10.0071 10.4314L6.54648 6.94077C6.40842 6.80153 6.24415 6.69101 6.06316 6.61559C5.88216 6.54017 5.68803 6.50134 5.49195 6.50134C5.29587 6.50134 5.10173 6.54017 4.92073 6.61559C4.73974 6.69101 4.57547 6.80153 4.43741 6.94077Z'
						fill='url(#paint0_linear_138_1601)'
					/>
					<defs>
						<linearGradient
							id='paint0_linear_138_1601'
							x1='9.04548'
							y1='6.50134'
							x2='18.2932'
							y2='10.7611'
							gradientUnits='userSpaceOnUse'>
							<stop stop-color='#4462EF' />
							<stop offset='1' stop-color='#1B3DD8' />
						</linearGradient>
					</defs>
				</svg>
			)}
		</div>
	);
};

export const CourseSingleWeekModuleCardCollapsedNew: React.FC<
	ModuleCardCollapsedPropsNew
> = ({ setSelectedIndex, moduleTitle, selectedIndex, index, weekDays }) => {
	const isSelected = selectedIndex === index;
	console.log('CourseModuleCardCollapsedNew', isSelected);

	return (
		<div
			className={'module-course-card-expanded'}
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignSelf: 'center',
				borderTop: 'none',
				background: 'white',
				width: '100%',
				marginLeft: '-12px',
			}}>
			{weekDays.map((item: any, index: number) => (
				<div key={index} className={'module-course-card-single-week'}>
					<div
						style={{
							color: '#1B1625',
							fontFamily: 'Gordita-Medium',
							fontSize: '16px',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: '24px', // or '160%'
						}}>{`Day ${item.day}`}</div>
					<div
						style={{
							color: '#4A4A4A',
							fontFamily: 'Gordita-Regular',
							fontSize: '14px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: '20px', // or '160%'
						}}>
						{item.task_description}
					</div>
				</div>
			))}
		</div>
	);
};

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './Login.css';
import './../../styles.css';
import PhoneInput from '../../components/phoneInput/PhoneInput';
import Button from '../../components/button/Button';
import ErrorComponent from '../../components/error/Error';
import { COLORS } from '../../utils/colors';
import Loader from '../../components/loader/Loader';
import signup_banner from '../../assets/webp-images/sign_up_baneer.webp';
import { isMobileDevice } from '../../utils/utils';
import { ReactComponent as BackArrow } from '../../assets/left_arrow_black.svg';
import { ReactComponent as SeekLogo } from '../../assets/seek-logo.svg';
interface LoginFormProps {
	onEnrollNowClickHandler: () => void;
	onFormSubmitHandler: (phone: string) => void;
	onBackArrowClick: () => void;
	user?: any;
	name?: String;
}

const LoginFrom: React.FC<LoginFormProps> = ({
	onFormSubmitHandler,
	onEnrollNowClickHandler,
	user,
	onBackArrowClick,
	name,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [phone, setPhone] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [loader, setLoader] = useState<boolean>(false);
	const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

	const customInputStyle: React.CSSProperties = {
		marginTop: '10px',
		height: '52px',
		width: '100%',
		borderRadius: '8px',
		border: '1px solid #D6D6D6',
	};

	useEffect(() => {
		if (!isKeyboardOpen && isMobileDevice()) {
			console.log('isKeyboardOpen change', isKeyboardOpen, isMobileDevice());
			window.scrollTo(0, 0);
		}
	}, [isKeyboardOpen]);

	const handlePhoneChange = (
		value: string,
		country: string,
		e: ChangeEvent<HTMLInputElement>,
		formattedValue: string
	): void => {
		setError('');
		setPhone(value);
	};

	return (
		<div className={'login-form-container'}>
			{loader && <Loader />}
			<div
				className={'header-container'}
				style={{ width: '100%', marginTop: '24px' }}>
				<BackArrow width={48} height={48} onClick={onBackArrowClick} />
				<SeekLogo
					style={{
						alignSelf: 'center',
						width: '100%',
						marginLeft: '12px',
						alignItems: 'center',
					}}
					width={108}
					height={44}
				/>
			</div>
			<img
				loading='lazy'
				src={signup_banner}
				className={'img-fluid'}
				alt=''
				style={{
					width: '200px',
					alignSelf: 'center',
					height: '180px',
					marginTop: '36px',
					objectFit: 'cover',
				}}
			/>
			<div
				style={{
					alignSelf: 'center',
					display: 'flex',
					flexDirection: 'column',
					marginTop: '32px',
				}}>
				<span className={'login-page-form-title'}>Login</span>
			</div>

			<div className={'login-page-input-title'} style={{ marginTop: '16px' }}>
				WhatsApp Number
			</div>
			<PhoneInput
				onFocusHandle={() => setIsKeyboardOpen(true)}
				onBlurHandle={() => setIsKeyboardOpen(false)}
				phoneNumber={phone}
				defaultCountry='in'
				onPhoneChange={handlePhoneChange}
				inputStyle={customInputStyle}
			/>
			<Button
				isSticky={false}
				style={{
					backgroundColor: COLORS.PRIMARY,
					borderRadius: '12px',
					alignSelf: 'center',
					width: '327px',
					height: '50px',
					marginTop: '54px',
				}}
				onClick={onFormSubmitHandler.bind(null, phone)}
				label={'Continue'}></Button>
			<span
				onClick={onEnrollNowClickHandler}
				className={'login-form-signup-text'}
				style={{ marginTop: '16px' }}>
				New to Seek? <span>Enrol Now</span>
			</span>
		</div>
	);
};

export default LoginFrom;

import BottomSheet from '../../../components/model/BottomSheet';
import RecordingsIcon1 from '../../../assets/new-icons/recordings-modal-icon-1.svg';
import RecordingsIcon2 from '../../../assets/new-icons/recordings-modal-icon-2.svg';
import RecordingsIcon3 from '../../../assets/new-icons/recordings-modal-icon-3.svg';
import RecordingBanner from '../../../assets/new-icons/recording_banner.svg';
import CloseRecordingModelIcon from '../../../assets/new-icons/close-popup.svg';

import './RecordingsModal.css';
import PrimaryButton from '../../../components-v2/buttons/PrimaryButton';

interface RecordingsModalProps {
    showRecordingsModal: boolean;
    recordingsAdded: boolean;
    viewBenefits: boolean;
    onHideRecordingsModal: (action: string) => void;
    offerPrice: string;
    actualPrice: string;
}

function RecordingsModal({
                             showRecordingsModal,
                             onHideRecordingsModal,
                             recordingsAdded,
                             viewBenefits,
                             offerPrice,
                             actualPrice,
                         }: RecordingsModalProps) {
    return (
        <BottomSheet
            customStyle={{height: 'max-content'}}
            show={showRecordingsModal}
            showHeader={false}
            title=''
            hideOnOverlayClick={false}
            onHide={() => onHideRecordingsModal('skip')}
            content={
                <div className='recordings-modal'>
                    {(viewBenefits || recordingsAdded) && (
                        <div
                            onClick={() => onHideRecordingsModal('skip')}
                            style={{
                                width: '100%',
                                alignItems: 'end',
                                display: 'flex',
                                justifyContent: 'end',
                            }}>
                            <img
                                style={{alignSelf: 'end'}}
                                src={CloseRecordingModelIcon}
                                alt=''
                            />
                        </div>
                    )}

                    <div
                        style={{
                            width: '100%',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '24px',
                            marginBottom: '32px',
                        }}>
                        <img src={RecordingBanner} alt=''/>
                    </div>

                    <div className='recordings-modal-text'>
                        <p className='recordings-modal-title'>
                            Get access to course recordings
                            <span>INR {offerPrice} for 6 weeks!</span>
                        </p>
                        <p className='recordings-modal-subtitle'>
                            *Limited time discount for you. Avail it soon.
                        </p>
                    </div>
                    <div className='recordings-modal-points'>
                        <div className='recordings-modal-points-item'>
                            <img src={RecordingsIcon1} alt=''/>
                            <p>Deepen your practice after course completion.</p>
                        </div>
                        <div className='recordings-modal-points-item'>
                            <img src={RecordingsIcon2} alt=''/>
                            <p>⁠Access recordings anytime, anywhere, till 6 weeks.</p>
                        </div>
                        <div className='recordings-modal-points-item'>
                            <img src={RecordingsIcon3} alt=''/>
                            <p>
                                Buy now for ₹{offerPrice} (₹{actualPrice} after this offer
                                ends).
                            </p>
                        </div>
                    </div>
                    <PrimaryButton
                        disabled={recordingsAdded}
                        onClick={() => onHideRecordingsModal('add')}
                        style={{
                            width: '100%',
                            marginBottom: '12px',
                            fontSize: '14px',
                            height: '44px',
                        }}>
                        Add Recordings
                    </PrimaryButton>
                    {!viewBenefits && !recordingsAdded && (
                        <p
                            onClick={() => onHideRecordingsModal('skip')}
                            className='recordings-modal-skip'>
                            Skip for now
                        </p>
                    )}
                </div>
            }
        />
    );
}

export default RecordingsModal;

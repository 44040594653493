import React, { CSSProperties, ReactNode } from 'react';
import './Button.css';

interface ButtonProps {
	onClick: () => void;
	label: ReactNode;
	style?: CSSProperties;
	disabled?: boolean;
	isSticky?: boolean;
}

const Button: React.FC<ButtonProps> = ({
	onClick,
	label,
	style,
	disabled,
	isSticky,
}) => {
	const buttonStyle: CSSProperties = {
		display: 'flex',
		width: '250px',
		height: '50px',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '12px',
		fontFamily: 'Gordita-Bold',
		fontSize: '16px',
		background: disabled
			? 'var(--Inactive-Color, #D6D6D6)'
			: 'var(--Primary_Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
		fontWeight: '700',
	};

	const combinedStyle: CSSProperties = {
		...buttonStyle,
		...style,
	};

	if (isSticky) {
		combinedStyle.position = 'fixed';
		combinedStyle.bottom = '10px';
		combinedStyle.left = '50%';
		combinedStyle.transform = 'translateX(-50%)';
	}

	return (
		<button
			className={`old-button ${disabled ? 'disabled' : ''}`}
			disabled={disabled}
			onClick={onClick}
			style={combinedStyle}>
			<span className={'old-button-title'}>{label}</span>
		</button>
	);
};

export default Button;

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addUsers } from '../../store/features/AuthSlice';
import '../home/components/AccountCreated.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
	getNudges,
	getUpcomingClasses,
	getUserByUserId,
	getYoutubeLiveClassLink,
} from '../../services/Firebase';
import { useNavigate } from 'react-router-dom';
import './LiveClassContainer.css';
import seek_logo_url from '../../assets/seek-logo.svg';
import { insertEmptyObjects } from '../../utils/utils';
import { Mixpanel } from '../../analytics/Mixpanel';
import { ReactComponent as LanguageIcon } from '../../assets/new-icons/language-icon.svg';
import { ReactComponent as MediumIntensity } from '../../assets/new-icons/medium-difficulty-level.svg';
import { ReactComponent as LowIntensity } from '../../assets/new-icons/easy-difficulty-level.svg';
import { ReactComponent as HighIntensity } from '../../assets/new-icons/high-difficulty-level.svg';
import { ReactComponent as WatchIcon } from '../../assets/new-icons/live-clock.svg';
import {
	setForceRefreshOnCoursePurchase,
	setNudges,
	setUpcomingClasses,
} from '../../store/features/UpcomingClassesSlice';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import Header from '../../components-v2/header/Header';
import LiveClassSlotContainer from './LiveClassSlotContainer';
import UpgradeBanner from '../../components-v2/upgrade-plan/UpgradeBanner';
import LiveClassCard from '../../components-v2/live-class/LiveClassCard';
import Shimmer from '../../components-v2/shimmer/Shimmer';
import ShimmerLoader from '../../components-v2/shimmer/Shimmer';
import useFetchStreamingPlan from '../../hooks/useFetchStreamingPlan';
import useFetchCoursesData from '../../hooks/useFetchCoursesData';
import isEmpty from 'lodash/isEmpty';
import IngressNudgesSlider from '../../components-v2/nudges/IngressNudgesSlider';
import { ReactComponent as RenewIcon } from '../../assets/new-icons/star.svg';
import IngressNudgesSwiper from '../../components-v2/nudges/IngressNudgesSwiper';
import closeIcon from '../../assets/new-icons/_xmark.svg';

const LiveClassContainer = memo(() => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const userData = useAuthenticatedUsers();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const force_refresh_on_plan_purchase = useSelector(
		(state: any) => state?.auth?.user?.force_refresh_on_plan_purchase
	);
	const user = useSelector((state: any) => state?.auth?.user?.data);

	const daysToExpire = useSelector(
		(state: any) => state.plan?.userPlan?.daysToExpire
	);
	const planName = useSelector((state: any) => state.plan?.userPlan?.title);

	const upcomingClasses = useSelector(
		(state: any) => state.upcomingClasses?.upcomingClasses?.data
	); // Assuming upcomingClasses state is stored in Redux
	const upcomingCourses = useSelector(
		(state: any) => state.upcomingClasses?.upcomingCourse?.data
	); // Assuming upcomingClasses state is stored in Redux

	const nudges = useSelector(
		(state: any) => state.upcomingClasses?.nudges?.data
	); // Assuming upcomingClasses state is stored in Redux
	const force_refresh_on_course_purchase = useSelector(
		(state: any) => state.upcomingClasses?.force_refresh_on_course_purchase
	); // Assuming upcomingClasses state is stored in Redux
	// console.log("force_refresh_on_course_purchase", force_refresh_on_course_purchase);

	const favouriteClasses = useSelector(
		(state: any) => state.upcomingClasses?.favouriteClasses?.data
	); // Assuming upcomingClasses state is stored in Redux

	const [classSlots, setClassSlots] = useState([]);
	const [displayDate, setDisplayDate] = useState();
	const [loader, setLoader] = useState(true);
	useFetchStreamingPlan(userData, user, force_refresh_on_plan_purchase);
	useFetchCoursesData(userData, user, upcomingCourses, favouriteClasses);

	const [showFloatingVideo, setShowFloatingVideo] = useState(false);
	const [expandFloatingVideo, setExpandFloatingVideo] = useState(false);
	const floatingVideoRef = useRef<HTMLDivElement>(null);
	const closeFloatingVideoRef = useRef<HTMLImageElement>(null);

	const onFloatingVideoClick = () => {
		if (!expandFloatingVideo) {
			setExpandFloatingVideo(true);
			floatingVideoRef.current!.style.width = '50%';
			Mixpanel.track('click_course_intro_video_expand', {
				event_page: 'live_classes',
				event_action: 'clicked',
				course_name: 'Freedom from Anger',
			});
		} else {
			Mixpanel.track('click_course_intro_video_to_redirect', {
				event_page: 'live_classes',
				event_action: 'clicked',
				course_name: 'Freedom from Anger',
			});
			localStorage.setItem('angerPipVideoClosed', 'true');
			navigate('/upcoming-course/IxcuwN6l13oaR476YXzm');
		}
	};

	const onFloatingVideoClose = () => {
		setShowFloatingVideo(false);
		localStorage.setItem('angerPipVideoClosed', 'true');
	};

	useEffect(() => {
		localStorage.removeItem('pipVideoClosed');
		if (upcomingCourses) {
			if (
				upcomingCourses.filter(
					(item: any) => item.courseId === 'IxcuwN6l13oaR476YXzm'
				)[0]?.batch[0].user_already_enrolled
			) {
				setShowFloatingVideo(false);
			} else {
				setShowFloatingVideo(true);
				document
					.getElementById('live-class-floating-video')
					?.addEventListener('loadedmetadata', () => {
						Mixpanel.track('view_course_intro_video', {
							event_page: 'live_classes',
							event_action: 'viewed',
							course_name: 'Freedom from Anger',
						});
						if (closeFloatingVideoRef.current) {
							closeFloatingVideoRef.current.style.display = 'initial';
						}
					});
			}
		} else {
			setShowFloatingVideo(false);
		}
	}, [upcomingCourses]);

	useEffect(() => {
		if (isEmpty(user) && !userData) {
			navigate('/');
		}
	}, [user, userData]);

	useEffect(() => {
		if (
			(isEmpty(user) && userData?.uid) ||
			(force_refresh_on_course_purchase && userData?.uid)
		) {
			getUserByUserId(userData?.uid).then((userLoginResult) => {
				dispatch(addUsers(userLoginResult?.userData));
				Mixpanel.identify(userData?.uid);
			});
		}
	}, [userData, force_refresh_on_course_purchase]);

	useEffect(() => {
		if (!upcomingClasses.length) {
			getUpcomingClasses(user.uid || userData?.uid)
				.then((result: any) => {
					console.log('result class', result.data);
					dispatch(setUpcomingClasses(result.data));
					setLoader(false);
				})
				.catch((err: any) => {
					console.log('getUpcomingClasses err', err);
				});
		} else {
			setLoader(false);
		}
	}, []);

	useEffect(() => {
		if (
			nudges === null ||
			force_refresh_on_course_purchase ||
			force_refresh_on_plan_purchase ||
			daysToExpire
		) {
			getNudges(user?.uid || userData?.uid)
				.then((nudeResponse: any) => {
					console.log('nudeResponse', nudeResponse?.results);
					let combinedNudges = [];
					if (
						(nudeResponse?.results?.length > 0 ||
							nudeResponse?.results?.length === 0) &&
						parseInt(daysToExpire) > 0 &&
						parseInt(daysToExpire) <= 7
					) {
						combinedNudges.push({
							Icon: RenewIcon,
							isVisible: true,
							nudge_type: 'Plan',
							priority: 1,
							redirection_link: '',
							title: `Renew your plan,<b>${daysToExpire + ' Days left'}`,
							user_already_enrolled: false,
						});
					}
					if (nudeResponse?.results?.length > 0 && daysToExpire) {
						combinedNudges = [...combinedNudges, ...nudeResponse.results];
					}

					setLoader(false);
					dispatch(setNudges(combinedNudges));
					dispatch(setForceRefreshOnCoursePurchase(false));
				})
				.catch((err: any) => {
					// console.log("getNudges err", err);
					setLoader(false); // Ensure loader is turned off even on error
				});
		} else {
			setLoader(false);
		}
	}, [
		daysToExpire,
		userData?.uid,
		force_refresh_on_course_purchase,
		force_refresh_on_plan_purchase,
	]);

	useEffect(() => {
		(async () => {
			const fetchAndUpdateLink = async () => {
				try {
					const youtubeLinkResponse = await getYoutubeLiveClassLink();
					setClassSlots(insertEmptyObjects(youtubeLinkResponse?.data));
					setDisplayDate(
						youtubeLinkResponse?.displayDate?.replace(/^[^,]+/, 'Schedule')
					);
				} catch (error) {
					console.error('Error fetching YouTube link:', error);
				}
			};
			await fetchAndUpdateLink();
			const intervalInMinutes = 2;
			const intervalId = setInterval(
				fetchAndUpdateLink,
				intervalInMinutes * 60 * 1000
			);
			// Clean up the interval on component unmount
			return () => clearInterval(intervalId);
		})();
	}, []);

	const onKarmaPointsClickHandler = useCallback(() => {
		Mixpanel.track('click_karma_points_badge', {
			event_action: 'clicked',
			context_user_id: user?.uid,
			total_karma_points: user?.total_karma || 0,
			event_page: 'live_classes',
		});
		navigate('/karma', {
			state: { email: user?.email, total_karma: user?.total_karma },
			replace: false,
		});
	}, [user, navigate]);

	const onClickUpgradePlanBannerHandler = useCallback(() => {
		Mixpanel.track('click_buy_now_banner', {
			event_action: 'clicked',
			user_type: '',
			event_page: 'live_classes',
		});
		navigate('/plan', { state: { user: user }, replace: false });
	}, [user]);

	const onCourseClickHandler = (index: number) => {
		console.log('onCourseClickHandler', index, nudges[index]?.course_name);
		if (nudges[index]?.nudge_type === 'Course') {
			Mixpanel.track('click_course_nudge', {
				event_action: 'clicked',
				event_page: 'live_classes',
				course_name: nudges[index]?.course_name,
			});
			navigate(`/upcoming-course/${nudges[index]?.redirection_link}`, {
				state: {
					user,
				},
			});
		} else if (nudges[index]?.nudge_type === 'Plan') {
			Mixpanel.track('click_plan_renewal', {
				event_action: 'clicked',
				event_page: 'live_classes',
				days_left: daysToExpire,
				plan_duration: planName,
			});
			navigate('/plan', { state: { user: user }, replace: false });
		}
	};

	const onCalendarClickHandler = useCallback(
		(calendarItem: any) => {
			// console.log("onCalendarClickHandler", upcomingClasses);
			Mixpanel.track('click_upcoming_class_details', {
				event_action: 'clicked',
				class_name: calendarItem.title,
				event_page: 'live_classes',
				instructor_name: calendarItem?.instructor,
				intensity: calendarItem?.effort,
				language: calendarItem?.language || 'English',
			});

			navigate('/upcoming-sessions', {
				state: {
					calendarItem,
					user,
					// isWithinStreamingWindow,
					// activeStreamingPlan
				},
			});
		},
		[user]
	);

	return (
		<div className={'live-class-container'}>
			<Header
				onClick={onKarmaPointsClickHandler}
				logoUrl={seek_logo_url}
				karma_points={user?.total_karma}
			/>
			{nudges != null && nudges?.length > 0 ? (
				<div
					style={{
						width: '100%',
						maxWidth: '400px',
						paddingTop: '2px',
						alignSelf: 'center',
						backgroundColor: '#EBEFFF',
						height: '54px',
					}}>
					{nudges?.length === 1 && (
						<IngressNudgesSlider
							onClick={onCourseClickHandler}
							IngressNudges={nudges}
							style={{
								width: 'auto',
								height: '48px',
								alignSelf: 'center',
								marginTop: '4px',
								paddingLeft: '16px',
								paddingRight: '16px',
							}}
							naturalSlideHeight={232}
							naturalSlideWidth={240}
						/>
					)}
					{nudges?.length === 2 && (
						<IngressNudgesSlider
							onClick={onCourseClickHandler}
							IngressNudges={nudges}
							style={{
								width: 'auto',
								height: '48px',
								alignSelf: 'center',
								marginTop: '4px',
								paddingLeft: '16px',
								paddingRight: '16px',
							}}
							naturalSlideHeight={232}
							naturalSlideWidth={240}
						/>
					)}
					{nudges?.length > 2 && (
						<IngressNudgesSwiper
							onClick={onCourseClickHandler}
							slides={nudges}
						/>
					)}
				</div>
			) : nudges == null ? (
				<Shimmer style={{ height: '64px' }} />
			) : null}

			{nudges != null && classSlots?.length > 0 && (
				<div className={'live-class-component-wrapper'}>
					{displayDate ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								maxWidth: '354px',
								width: '100%',
							}}>
							<span
								style={{
									color: '#1B1625',
									fontFamily: 'Galaxie-Copernicus-Book',
									fontSize: '20px',
									width: '100%',
									alignSelf: 'start',
									textAlign: 'start',
									fontStyle: 'normal',
									fontWeight: 500,
									lineHeight: '32px',
								}}>
								{displayDate}
								<span
									style={{
										color: '#1B1625',
										fontFamily: 'Gordita-Bold',
										fontSize: '14px',
										alignSelf: 'start',
										textAlign: 'start',
										fontStyle: 'normal',
										fontWeight: 500,
										lineHeight: '20px',
									}}>
									(IST)
								</span>
							</span>
						</div>
					) : (
						<Shimmer
							style={{ height: '100px', width: '90%', maxWidth: '375px' }}
						/>
					)}
				</div>
			)}

			{nudges != null && classSlots?.length > 0 ? (
				<LiveClassSlotContainer slots={classSlots} />
			) : (
				<Shimmer style={{ height: '100px', width: '90%', maxWidth: '375px' }} />
			)}
			{typeof daysToExpire === 'number' && daysToExpire <= 0 && (
				<UpgradeBanner
					daysToExpire={daysToExpire}
					onCtaClick={onClickUpgradePlanBannerHandler}
					customStyle={{ marginTop: '32px' }}
					title={
						'Upgrade to Seek Membership today to experience the daily live classes.'
					}
					subtitle={
						'With Seek Membership you will get variety of daily live classes, community access and 24 hours support.'
					}
					ctaText={'Buy Now'}
				/>
			)}

			{nudges != null && upcomingClasses?.length > 0 ? (
				upcomingClasses?.map((upcomingClass: any, index: number) => (
					<LiveClassCard
						chipContainerCustomStyle={{ marginLeft: '0' }}
						classData={upcomingClass}
						onLiveClassClickHandler={onCalendarClickHandler}
						isLocked={daysToExpire <= 0 && daysToExpire != null}
						customStyle={{
							marginTop: index === 0 ? '24px' : '8px',
							marginBottom:
								index === upcomingClasses && upcomingClasses?.length - 1
									? '64px'
									: '0px',
						}}
						icons={[
							{
								title: upcomingClass?.duration + ' Mins',
								icon: WatchIcon,
							},
							{},
							{
								title: `${upcomingClass?.effort} Intensity`,
								icon:
									upcomingClass?.effort === 'Medium'
										? MediumIntensity
										: upcomingClass?.effort === 'Hard'
										? HighIntensity
										: LowIntensity,
							},
							{},
							{
								title: upcomingClass?.language || 'English',
								icon: LanguageIcon,
							},
						]}
						title={upcomingClass?.title}
						imageUrl={
							upcomingClass?.instructorData?.photo || upcomingClass?.thumbnail
						}
						subtitle={'By ' + upcomingClass?.instructor}
						primaryButtonText={'Join Class'}
					/>
				))
			) : (
				<>
					<ShimmerLoader />
					<ShimmerLoader />
				</>
			)}

			{upcomingCourses.length &&
			showFloatingVideo &&
			!localStorage.getItem('angerPipVideoClosed') ? (
				<div ref={floatingVideoRef} className='live-class-floating-video'>
					<video
						id='live-class-floating-video'
						onClick={onFloatingVideoClick}
						src={
							'https://firebasestorage.googleapis.com/v0/b/seek-c3955.appspot.com/o/videos%2FMala%20intro%20compressed%20(1).mp4?alt=media&token=25d37029-075a-4a09-aae4-dbfcd05f3ff2'
						}
						loop
						playsInline
						autoPlay
						muted={!expandFloatingVideo}></video>
					<img
						ref={closeFloatingVideoRef}
						onClick={onFloatingVideoClose}
						src={closeIcon}
						alt=''
					/>
				</div>
			) : (
				<></>
			)}
		</div>
	);
});

export default LiveClassContainer;

import React, {useEffect, useState} from 'react'
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../services/Firebase";
import './knowYourInstructor.css'
import InstructorFeatures from "../course/components/InstructorFeatures";

interface InstructorSpecialityProps {
    index: number;
    title: string;
    subtitle: string;

}

const InstructorSpeciality: React.FC<InstructorSpecialityProps> = ({subtitle, index, title}) => {
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'start', // Align items to the ends of the container
            alignItems: 'center', // Vertically center items
        }}>
            <div style={{
                marginRight: '8px',
                display: 'flex',
                alignSelf: 'center',
                alignItems: 'center', // Vertically center items
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path
                        d="M11.1685 21.5667C12.4903 22.5361 14.3243 20.2684 15.9637 20.2769C17.6021 20.2845 19.4144 22.5701 20.7447 21.6139C22.0751 20.6568 20.4858 18.2114 20.9999 16.6552C21.5139 15.1 24.2474 14.0814 23.7494 12.5214C23.2505 10.9605 20.4329 11.7164 19.112 10.746C17.7911 9.77657 17.6682 6.86258 16.0298 6.85408C14.3914 6.84652 14.2393 9.75956 12.9089 10.7158C11.5785 11.672 8.76848 10.8896 8.25447 12.4449C7.7414 14.0002 10.4645 15.0452 10.9634 16.6061C11.4614 18.167 9.84847 20.5973 11.1694 21.5667H11.1685ZM12.6141 25.5446C14.4245 24.921 15.3864 25.0599 15.7775 26.1985C16.1697 27.3361 15.0188 28.7638 13.2084 29.3865C11.3981 30.0101 9.72469 29.9165 9.4658 29.1625C9.2069 28.4085 10.8037 26.1682 12.6141 25.5446ZM7.61763 23.5793C9.32596 23.5292 10.1102 23.9024 10.1414 24.9767C10.1735 26.0501 8.81478 26.9629 7.1055 27.0129C5.39718 27.064 3.99498 26.5414 3.9742 25.83C3.95246 25.1185 5.9093 23.6303 7.61763 23.5793ZM5.04757 19.6883C6.51685 20.0445 7.09795 20.5462 6.87401 21.4703C6.65008 22.3935 5.27718 22.8536 3.80884 22.4983C2.33957 22.1421 1.26525 21.3683 1.41359 20.7569C1.56194 20.1447 3.5783 19.3321 5.04757 19.6883Z"
                        fill="#FAA53D"/>
                    <path
                        d="M4.86809 15.203C6.13989 16.0213 6.52351 16.6865 6.0095 17.4849C5.49549 18.2842 4.04794 18.2691 2.77614 17.4509C1.50529 16.6326 0.744668 15.5498 1.08482 15.0197C1.42498 14.4906 3.59724 14.3847 4.86809 15.203ZM5.04195 10.9955C5.89328 11.9706 6.05202 12.6084 5.4388 13.1441C4.82652 13.6799 3.63881 13.3236 2.78748 12.3485C1.93521 11.3734 1.56671 10.3019 1.973 9.94762C2.3793 9.59329 4.18967 10.0204 5.04195 10.9955ZM6.6643 7.12528C7.05736 8.35834 6.94776 9.00653 6.17296 9.25314C5.39722 9.49975 4.45046 8.70039 4.05739 7.46733C3.66527 6.23333 3.75409 5.10326 4.2681 4.93979C4.78116 4.77633 6.27123 5.89128 6.6643 7.12528ZM9.35907 5.15995C9.13419 6.1275 8.80821 6.51206 8.19971 6.37128C7.59122 6.22955 7.28035 5.33097 7.50523 4.36248C7.73011 3.39493 8.23184 2.68438 8.63435 2.77793C9.03781 2.87147 9.58395 4.1924 9.35907 5.15995ZM12.7833 4.65728C11.9943 5.26011 11.4973 5.34892 11.1184 4.85287C10.7386 4.35681 11.0712 3.46485 11.8611 2.86202C12.651 2.25825 13.4901 2.03053 13.7414 2.35935C13.9927 2.68816 13.5732 4.0535 12.7833 4.65728Z"
                        fill="#FFF3E4"/>
                    <path
                        d="M19.4019 25.5437C17.5915 24.921 16.6297 25.0599 16.2385 26.1985C15.8473 27.3361 16.9972 28.7638 18.8076 29.3865C20.618 30.0101 22.2913 29.9165 22.5502 29.1625C22.8101 28.4085 21.2123 26.1673 19.4019 25.5437ZM24.3984 23.5793C22.6901 23.5292 21.9068 23.9024 21.8746 24.9768C21.8425 26.0501 23.2022 26.9629 24.9105 27.013C26.6189 27.064 28.021 26.5415 28.0418 25.83C28.0636 25.1185 26.1077 23.6303 24.3984 23.5793ZM26.9685 19.6883C25.4992 20.0445 24.9181 20.5462 25.142 21.4703C25.366 22.3935 26.7389 22.8536 28.2081 22.4984C29.6774 22.1421 30.7508 21.3683 30.6024 20.757C30.4541 20.1447 28.4387 19.3321 26.9685 19.6883Z"
                        fill="#FAA53D"/>
                    <path
                        d="M27.148 15.203C25.8771 16.0213 25.4926 16.6865 26.0066 17.4849C26.5215 18.2843 27.9691 18.2691 29.2399 17.4509C30.5117 16.6326 31.2714 15.5498 30.9312 15.0197C30.5911 14.4906 28.4198 14.3848 27.148 15.203ZM26.9741 10.9955C26.1228 11.9706 25.964 12.6084 26.5773 13.1442C27.1895 13.6799 28.3772 13.3237 29.2286 12.3486C30.0809 11.3735 30.4494 10.302 30.044 9.94765C29.6377 9.59332 27.8264 10.0204 26.9741 10.9955ZM25.3518 7.12531C24.9596 8.35837 25.0683 9.00656 25.8431 9.25317C26.6198 9.49978 27.5665 8.70042 27.9596 7.46736C28.3517 6.23335 28.262 5.10329 27.7489 4.93982C27.2349 4.77636 25.7448 5.89131 25.3518 7.12531ZM22.6579 5.15903C22.8828 6.12658 23.2088 6.51115 23.8173 6.37036C24.4258 6.22863 24.7367 5.33006 24.5118 4.36156C24.2869 3.39401 23.7852 2.68347 23.3827 2.77701C22.9792 2.87055 22.4331 4.19148 22.6579 5.15903ZM19.2337 4.65636C20.0236 5.25919 20.5197 5.34801 20.8995 4.85195C21.2784 4.35589 20.9458 3.46393 20.1559 2.8611C19.366 2.25733 18.5269 2.02962 18.2756 2.35843C18.0243 2.68725 18.4438 4.05259 19.2337 4.65636Z"
                        fill="#FFF3E4"/>
                </svg>
            </div>
            <div style={{ // Container for title and subtitle
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'center',
                marginTop: '12px',
            }}>
                <span style={{
                    color: '#1B1625',
                    fontFamily: 'Gordita-Medium',
                    fontSize: '14px',
                    fontWeight: 500,
                    minWidth: '324px',
                    lineHeight: '20px',
                }}>{title}</span>
                <span style={{
                    color: '#A4A4A4',
                    fontFamily: 'Gordita-Regular',
                    fontSize: '12px',
                    fontWeight: 500,
                    minWidth: '324px',
                    lineHeight: '16px',
                }}>{subtitle}</span>
                <div style={{
                    width: '302px',
                    height: '1px',
                    background: '#D6D6D6',
                    borderBottom: '1px dotted #D6D6D6',
                    marginTop: '12px'
                }}></div>
            </div>
        </div>
    );
};

const KnowYourInstructor = ({calendarItem}: any) => {
    const [instructorUrl, setInstructorUrl] = useState('');
    const parsedBio = calendarItem?.instructorData?.bio?.split('/');


    useEffect(() => {
        (async () => {
            try {
                const instructorImageRef = ref(storage, calendarItem?.instructorData?.thumbnail);
                const instructorUrl = await getDownloadURL(instructorImageRef);
                setInstructorUrl(instructorUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        })();
    }, [calendarItem]);

    return (
        <div className={'know-your-instructor-container'}>
            <span className={'instructor-name-heading'}>Know your Instructor</span>
            <div className="instructor-card-image-modify">
                <img
                    src={instructorUrl}
                    alt=""
                />
            </div>

            <span style={{
                color: '#2A2A2A',
                fontFamily: 'Gordita-Medium',
                fontSize: '16px',
                marginTop: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px'
            }}>{`${calendarItem?.instructorData?.name}`}</span>

            <div style={{
                width: '95%',
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'start',
                marginTop: '12px',
            }}>
                {parsedBio?.length > 0 && <p style={{
                    color: '#2A2A2A',
                    fontFamily: 'Gordita-Regular',
                    fontSize: '14px',
                    width: '100%',
                    fontWeight: 400,
                    marginTop: '-10px',
                    lineHeight: '24px'
                }}>{parsedBio?.[0]}</p>}

                {parsedBio?.length > 1 && <p style={{
                    color: '#2A2A2A',
                    fontFamily: 'Gordita-Regular',
                    fontSize: '14px',
                    width: '100%',
                    fontWeight: 400,
                    marginTop: '-10px',
                    lineHeight: '24px'
                }}>{parsedBio?.[1]}</p>}

                {parsedBio?.length > 2 && <p style={{
                    color: '#2A2A2A',
                    fontFamily: 'Gordita-Regular',
                    fontSize: '14px',
                    width: '100%',
                    fontWeight: 400,
                    marginTop: '-10px',
                    lineHeight: '24px'
                }}>{parsedBio?.[2]}</p>}
            </div>

            {calendarItem?.instructorData?.achievements_title?.map((item: any, index: number) =>
                <InstructorSpeciality
                    subtitle={calendarItem?.instructorData?.achievements_description[index]}
                    title={item} index={index}/>)}

            {
                calendarItem?.instructorData?.recognitions?.map((url: string) => {
                    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/);
                    return match ? match[1] : null;
                })?.map((recognition: any, index: any) =>
                    <InstructorFeatures index={index} videoId={recognition}></InstructorFeatures>
                )
            }

        </div>
    )
}

export default KnowYourInstructor

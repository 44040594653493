import React, { useEffect } from 'react';
import { ReactComponent as PaymentSuccess } from '../../assets/payment-success.svg';
import './PaymentSuccessScreen.css';
import { useLocation, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components-v2/buttons/PrimaryButton';
import { Mixpanel } from '../../analytics/Mixpanel';
import { useSelector } from 'react-redux';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import Button from '../../components/button/Button';

interface PaymentSuccessModalProps {}

const PlanPaymentSuccess: React.FC<PaymentSuccessModalProps> = ({}) => {
	const userData = useAuthenticatedUsers(true);
	const location = useLocation();
	const selectedPlanVariant =
		location.state && location.state?.selectedPlanVariant;
	const prevScreenName = location.state && location.state?.prevScreenName;
	const currently_active_variant =
		location.state && location.state?.currently_active_variant;
	console.log('prevScreenName', prevScreenName, currently_active_variant);
	const user = useSelector((state: any) => state?.auth?.user?.data);
	const navigate = useNavigate();

	// const onJoinWhatsAppsCommunityHandler = async () => {
	// 	try {
	// 		// TODO update course whatsapp group link
	// 		let whatsappGroupLink =
	// 			'https://chat.whatsapp.com/GnKYkPjiPnV8WdePWtDw3s';
	// 		Mixpanel.track('click_join_community', {
	// 			event_action: 'clicked',
	// 			event_page: 'payment_successful_screen',
	// 			context_user_id: user?.uid,
	// 			phone: userData?.phoneNumber || userData?.phone,
	// 		});
	// 		window.open(whatsappGroupLink, '_blank');
	//
	//
	// 		if (user.isProfileCompleted || userData.isProfileCompleted) {
	// 			navigate('/live-classes');
	// 		} else {
	// 			navigate('/complete-your-profile');
	// 		}
	//
	// 		navigate('/get-started');
	//
	// 	} catch (error) {
	// 		console.error('Error:', error);
	// 		// Handle the error as needed
	// 	}
	// };

	const onContinueFromPaymentSuccess = async () => {
		try {
			Mixpanel.track('click_continue', {
				event_action: 'clicked',
				event_page: 'payment_successful_screen',
				context_user_id: user?.uid,
				phone: userData?.phoneNumber || userData?.phone,
			});
			if (
				prevScreenName === 'AccountCreated' ||
				currently_active_variant === 'FREE'
			) {
				navigate('/get-started');
			} else {
				navigate('/home');
			}
		} catch (error) {
			console.error('Error:', error);
			// Handle the error as needed
		}
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		Mixpanel.track('view_payment_successful_screen', {
			event_action: 'viewed',
			plan_duration: selectedPlanVariant?.duration,
			phone: userData?.phoneNumber || userData?.phone,
		});

		return () => {
			document.body.style.overflow = 'initial';
		};
	}, []);

	return (
		<div className='payment-success-container'>
			<PaymentSuccess width={120} height={120} />
			<span className='payment-success-hurray'>Payment Successful 🎉</span>
			<span className='payment-success-plan-description'>
				You have successfully bought{' '}
				<span>{selectedPlanVariant?.name || 'a plan'}</span>
			</span>
			<div
				style={{
					width: '240px',
					height: '40px',
					alignSelf: 'center',
					display: 'flex',
					alignItems: 'center',
				}}>
				{/*<PrimaryButton*/}
				{/*	isSticky={false}*/}
				{/*	style={{*/}
				{/*		alignSelf: 'center',*/}
				{/*		height: '40px',*/}
				{/*		marginTop: '24px',*/}
				{/*		borderRadius: '8px',*/}
				{/*	}}*/}
				{/*	onClick={onJoinWhatsAppsCommunityHandler}>*/}
				{/*	Join WhatsApp Community*/}
				{/*</PrimaryButton>*/}

				<Button
					isSticky={true}
					style={{
						alignSelf: 'center',
						marginBottom: '12px',
						width: '90%',
						background:
							'var(--Primary_Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
					}}
					onClick={onContinueFromPaymentSuccess}
					label={'Continue'}></Button>
			</div>
		</div>
	);
};
export default PlanPaymentSuccess;
